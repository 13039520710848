import { Grid, GridProps, styled } from '@mui/material';
import { FC } from 'react';

const FormContent: FC<GridProps> = styled(Grid as FC<GridProps>)(() => ({
	width: '100%',
	maxWidth: '650px',
	'.MuiGrid-item': {
		paddingLeft: 0,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	}
}));

const StyledForm = styled('form')(() => ({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	margin: '16px 0',
}));

export { FormContent, StyledForm };