import { useCallback, useEffect, useState } from 'react';
import { stateSetter } from 'utils/types/state';
import { fetchExtraModules, fetchModules } from './index.actions';
import { IModuleData, ModulesReturnType } from './index.types';

function useModules<T extends IModuleData | IModuleData[]>(
	getModulesOnRender: boolean | undefined = undefined,
	subscriptionId: string | undefined = undefined
): ModulesReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const getModules = useCallback(
		async () => {
			try {
				setLoading(true);
				const response = await fetchModules();
				setData(response as T);
			} catch (message) {
				console.error(message);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getExtraModules = useCallback(
		async (subId: string) => {
			try {
				setLoading(true);
				const response = await fetchExtraModules(subId);
				setData(response as T);
			} catch (message) {
				console.error(message);
				setData(null);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getModulesOnRender) {
			getModules();
		}
		// eslint-disable-next-line
	}, [getModulesOnRender]);

	useEffect(() => {
		if (subscriptionId) {
			getExtraModules(subscriptionId);
		}
		// eslint-disable-next-line
	}, [subscriptionId]);

	return {
		data: data || ([] as IModuleData[] as T),
		setData: setData as stateSetter<T>,
		loading,
		getModules,
		getExtraModules,
	};
}

export default useModules;