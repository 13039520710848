import { AxiosError } from 'axios';
import { request } from 'utils/config/axios';
import { decryptData } from 'utils/functions/decript';

async function requestCities(countyIso: string): Promise<string[]> {
	try {
		const res = await request.get(`cities?iso2=${countyIso}`);
		const decrypted = decryptData(res);
		if (decrypted.cities)
			return decrypted.cities;
		throw new Error('Invalid response received!');
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get cities.');
	}
}

export { requestCities };
