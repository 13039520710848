import { useCallback, useState } from 'react';
import { postServiceRequest } from './index.actions';
import { ClientFormData } from './index.types';

export interface UseContactReturnType {
	loading: boolean;
	error: boolean;
    message: string | null;
    sentRequest: (data: ClientFormData, onSuccess?: () => void) => Promise<void>;
    resetFlags: () => void;
}

function useServiceRequest(): UseContactReturnType {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [message, setMessage] = useState<string | null>(null);

	const sentRequest = useCallback(
		async (data: ClientFormData, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await postServiceRequest(data);
				if (typeof response === 'string') {
					setMessage(response);
					onSuccess && onSuccess();
				} else {
				    setError(true);
				}
			} catch (e) {
				console.error(e);
				setMessage('Falied to send request');
				setError(true);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setLoading, setMessage]
	);

	const resetFlags = (): void => {
		setMessage(null);
		setError(false);
	};

	return {
		loading,
		message,
		sentRequest,
		resetFlags,
		error
	};
}

export default useServiceRequest;
