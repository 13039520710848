import React from 'react';
import { Grid, } from '@mui/material';
import DebounceInput from 'components/input/debouonce-input';
import { stateSetter } from 'utils/types/state';
import { ClientFormData } from 'hooks/fetch-hooks/use-service-request/index.types';
import { useTranslation } from 'react-i18next';
import { FormContent, StyledForm } from '../index.styled';
import { IErrorDebounce } from '../subsribe-page/component';

interface IProps {
	formData: ClientFormData
	setFormData: (data: ClientFormData) => void;
	setErrorDebounce: stateSetter<IErrorDebounce>;
}

const AppForm: React.FC<IProps> = ({ formData, setFormData, setErrorDebounce }) => {
	const updateErrorDebounce = (newErrorState: boolean ):void => {
		setErrorDebounce((prev) => ({
			...prev,
			errorName: newErrorState
		}));
	};

	const { t } = useTranslation(); 

	return (
		<StyledForm autoComplete="off" >
			<FormContent container spacing={1} justifyContent={'center'}>
				<Grid item xs={12}>
					<DebounceInput
						required
						name="cui"
						label={t('views.subscribe.subscribe-component.first-section.inputs.cui.name')}
						value={formData.company.cui}
						onChange={(e): void => setFormData({
							...formData,
							company: {
								...formData.company,
								cui: e.target.value
							}
						})}
						checkVal='cui'
						methodRequest='CUI'
						onErrorDebounce={updateErrorDebounce}
					/>
				</Grid>
				<Grid item xs={12}>
					<DebounceInput
						required
						name="name"
						label={t('views.subscribe.subscribe-component.first-section.inputs.brand-name.name')}
						// eslint-disable-next-line
						placeholder={t('views.subscribe.subscribe-component-first.section.inputs.brand-name.placeholder')}
						value={formData.site.name}
						onChange={(e): void => setFormData({
							...formData,
							site: {
								...formData.site,
								name: e.target.value
							}
						})}
						checkVal='appName'
						methodRequest='APP_NAME'
						onErrorDebounce={updateErrorDebounce}
					/>
				</Grid>
			</FormContent>
		</StyledForm>
	);
};

export default AppForm;
