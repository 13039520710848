import { useEffect, useState } from 'react';
import { County, CountiesDictionary, UseCountiesReturnType } from './index.types';
import { requestCounties } from './index.actions';

const getCountiesDictionary = (counties: County[]): CountiesDictionary =>
	counties.reduce((dictionary, county) => {
		// eslint-disable-next-line no-param-reassign
		dictionary[county.name as keyof CountiesDictionary] = county.iso2;
		return dictionary;
	}, {} as CountiesDictionary);

function useCounties(): UseCountiesReturnType {
	const [counties, setCounties] = useState<CountiesDictionary>({});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<boolean | string>(false);

	useEffect(() => {
		(async (): Promise<void> => {
			setLoading(true);
			try {
				const countiesRetrieved = await requestCounties();
				if (countiesRetrieved instanceof Array) setCounties(getCountiesDictionary(countiesRetrieved));
				else if (typeof countiesRetrieved === 'string') setError(countiesRetrieved);
				else setError(true);
			} catch (e) {
				if (e instanceof Error) setError(e.message);
				else setError(true);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	return { counties, error, loading };
}

export default useCounties;
