import { Checkbox, Grid, Typography } from '@mui/material';
import { FC, memo } from 'react';
import { IPlansData } from 'hooks/fetch-hooks/use-plans/index.types';
import { StyledGridContainer, StyledCard } from './index.styled';

interface IProps {
	plan: IPlansData;
	handleCheck: (planId: string) => void;
	selectedPlan: string | null;
}

const BasePlan: FC<IProps> = memo(({ handleCheck, plan, selectedPlan}) => (
	<StyledCard>
		<StyledGridContainer container item xs={12} spacing={2}>
			<Grid item xs={8}>
				<Typography variant='h5' width={'100%'} textAlign={'start'}>{plan.name}</Typography>
			</Grid>
			<Grid container justifyContent={'flex-end'} item xs={4} gap={3}>
				<Typography variant='h4' textAlign='end' color='primary.dark'>
					{plan.price} lei
				</Typography>
				<Checkbox value={plan._id} checked={plan?._id === selectedPlan} 
					onChange={(e) => handleCheck(e.target.value)} />
			</Grid>
			<Grid item xs={12}>
				<Typography variant='h6'>
					{`Planul de ${plan.name} pentru abonamentul nostru de management al cabinetului 
					medical oferă funcționalități esențiale și instrumente indispensabile 
					pentru eficiența și organizarea activității cabinetului tău medical.
					Cu acest plan, poți gestiona pacienții și programările într-un mod simplu și eficient,
					fără să fii copleșit de caracteristici suplimentare.`}
				</Typography>
			</Grid>
		</StyledGridContainer>
	</StyledCard>
));

export default BasePlan;
