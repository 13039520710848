import { AxiosError } from 'axios';
import { request } from 'utils/config/axios';
import { decryptData } from 'utils/functions/decript';
import { County } from './index.types';

async function requestCounties(): Promise<County[]> {
	try {
		const res = await request.get('counties');
		const decrypted = decryptData(res);
		if (decrypted.counties)
			return decrypted.counties;
		throw new Error('Invalid response received!');
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get counties.');
	}
}

export { requestCounties };
