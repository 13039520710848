import { useCallback, useEffect, useState } from 'react';
import { stateSetter } from 'utils/types/state';
import { fetchPlans } from './index.actions';
import { IPlansData, PlansReturnType } from './index.types';

function usePlans<T extends IPlansData | IPlansData[]>(
	getPlansOnRender: boolean | undefined = undefined
): PlansReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const getPlans = useCallback(
		async () => {
			try {
				setLoading(true);
				const response = await fetchPlans();
				console.log('Fetched Plans Data:', response);
				setData(response as T);
			} catch (message) {
				console.error(message);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getPlansOnRender) {
			getPlans();
		}
		// eslint-disable-next-line
	}, [getPlansOnRender]);

	return {
		data: data || ([] as IPlansData[] as T),
		setData: setData as stateSetter<T>,
		getPlans,
		loading
	};
}

export default usePlans;