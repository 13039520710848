// PlanFeatures.tsx
import { CircularProgress, Grid, Typography } from '@mui/material';
import Flex from 'components/flex';
import { IModuleData } from 'hooks/fetch-hooks/use-modules/index.types';
import { IPlansData } from 'hooks/fetch-hooks/use-plans/index.types';
import { ClientFormData } from 'hooks/fetch-hooks/use-service-request/index.types';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { FormContent, StyledForm } from '../index.styled';
import BasePlan from './base-plan';
import Modules from './feature-accordion';

interface IProps {
	formData: ClientFormData;
	setFormData: Dispatch<SetStateAction<ClientFormData>>;
	plansData: IPlansData[];
	loadingModules: boolean;
	moduleData: IModuleData[];
}

const PlanFeatures: FC<IProps> = ({ formData, setFormData, plansData, loadingModules, moduleData }) => {
	// eslint-disable-next-line
	const basePlanPrice = useMemo(() => plansData.find((el) => el._id === formData.subscriptionPlan)?.price || 0, [formData.subscriptionPlan, plansData]);

	const totalPrice = useMemo(() => formData.modules.reduce((total, module) => {
		const featurePrice = moduleData.find((el) => el._id === module)?.price || 0;
		return total + featurePrice;
	}, basePlanPrice), [basePlanPrice, formData.modules, moduleData]);

	const handlePlan = (planId: string): void => {
		setFormData((prev) => ({
			...prev,
			subscriptionPlan: planId,
			modules: []
		}));
	};

	const handleCheckModule = (newModule: string): void => {
		if (formData.modules.includes(newModule)) {
			setFormData((prev) => ({
				...prev,
				modules: prev.modules.filter((module) => module !== newModule)
			}));
		} else {
			setFormData((prev) => ({ ...prev, modules: [...prev.modules, newModule] }));
		}
	};

	return (
		<StyledForm autoComplete='off'>
			<FormContent container spacing={1} justifyContent={'center'}>
				<Flex gap={2} mt={2}>
					{plansData.length > 0 ? (
						plansData.map((plan) => (
							<BasePlan
								key={plan._id}
								plan={plan}
								selectedPlan={formData.subscriptionPlan}
								handleCheck={handlePlan}
							/>
						))
					) : (
						<Typography>No plans available</Typography>
					)}
				</Flex>
				{loadingModules && (
					<Flex justifyCenter width='100%'>
						<CircularProgress />
					</Flex>
				)}
				{moduleData.length > 0 && (
					<Modules
						formData={formData}
						modules={moduleData}
						handleCheckModule={handleCheckModule}
					/>
				)}
				<Grid item xs={12}>
					<Typography>Pret total: {totalPrice} lei</Typography>
				</Grid>
			</FormContent>
		</StyledForm>
	);
};

export default PlanFeatures;
