const API_URL = String(process.env.REACT_APP_API_URL);
const GUESTS_URL = String(process.env.REACT_APP_API_GUESTS_URL);
const CRYPTO_KEY = String(process.env.REACT_APP_CRYPTO_JS_SK);
const APPLICATION_ID = String(process.env.REACT_APP_APPLICATION_ID);

const config = {
	api: {
		url: API_URL,
		guestsUrl: GUESTS_URL,
	},
	crypto: {
		crypto_key: CRYPTO_KEY,
	},
	applicationId: APPLICATION_ID,
};

export default config;
