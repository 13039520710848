import { FC } from 'react';

const Logo: FC = () => (
	<img
		src="https://storage.googleapis.com/sbdcloud/Dent.png"
		alt="dental-hub-logo"
		height={'200px'}
	// style={{marginTop: '20px'}}
	/>
);

export default Logo;
