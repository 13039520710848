import { useCallback, useState } from 'react';
import {validateAppName, validateCui, validateOwner } from './index.actions';
import { 
	IUseValidateParams, IValidateMethods, IValidateParamsBase, 
	Temp, ValidationMethod 
} from './index.types';

interface UseValidateReturnType {
    error: boolean | null;
    validateData: <T extends IValidateParamsBase>(props: IUseValidateParams<T>) => Promise<void>
    clearError: () => void;
    isLoading: boolean;
    isValid: boolean | null;
	errorMessage: string | null
}

const Methods: IValidateMethods = {
	CUI: validateCui as ValidationMethod,
	APP_NAME: validateAppName as ValidationMethod,
	OWNER: validateOwner as ValidationMethod,
};

function useValidateData(): UseValidateReturnType {
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [error, setError] = useState<boolean | null>(null);
	const [isValid, setIsValid] = useState<boolean | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
    
	const validateData = useCallback(
		async <T extends IValidateParamsBase>(props: IUseValidateParams<T>) => {
			const { methodRequest, paramsObject} = props;
			setLoading(true);
			try {
				const paramsTem = paramsObject as unknown as Temp;
				const dataResponse = await Methods[methodRequest](paramsTem);
				if( typeof dataResponse === 'number'){
					if( dataResponse === 200){
						setIsValid(true);
						setError(false);
					} else if( dataResponse === 409) {
						setIsValid(false);
						setError(true);
						setErrorMessage('Value already used!');
					} else {
						setIsValid(false);
						setError(true);
						setErrorMessage('Something went wrong, please try again!');
					}
				}
			} catch (e) {
				console.log(e);
				setIsValid(false);
				setError(true);
				setErrorMessage('Something went wrong, please try again!');
			} finally {
				setLoading(false);
			}
		},[]
	);

	const resetData = (): void => {
		setError(null);
		setIsValid(null);
		setErrorMessage(null);
	};
  
	return {
		error,
		validateData,
		clearError: resetData,
		isLoading: loading,
		isValid,
		errorMessage
	};
};

export default useValidateData;