import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { StyledInput } from 'components/input/base/index.styled';
import DebounceInput from 'components/input/debouonce-input';
import { stateSetter } from 'utils/types/state';
import { ClientFormData } from 'hooks/fetch-hooks/use-service-request/index.types';
import { useTranslation } from 'react-i18next';
import { FormContent, StyledForm } from '../index.styled';
import { IErrorDebounce } from '../subsribe-page/component';

interface IProps {
	formData: ClientFormData
	setFormData: (data: ClientFormData) => void;
	setErrorDebounce: stateSetter<IErrorDebounce>;
}

const ContactForm: React.FC<IProps> = ({ formData, setFormData, setErrorDebounce }) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const phoneRegex = /^\d{10}$/;
	const { t } = useTranslation();

	const [formValidity, setFormValidity] = useState({
		emailValid: true,
		phoneValid: true,
	});

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { name, value } = event.target;

		setFormData({
			...formData,
			owner: {
				...formData.owner,
				[name]: value,
			}
		});

		if (name === 'email') {
			setFormValidity({
				...formValidity,
				emailValid: emailRegex.test(value),
			});
		} else if (name === 'phoneNumber') {
			setFormValidity({
				...formValidity,
				phoneValid: phoneRegex.test(value),
			});
		}
	};

	const updateErrorDebouncePhone = (newErrorState: boolean): void => {
		setErrorDebounce((prev) => ({
			...prev,
			errorPhone: newErrorState
		}));
	};

	const updateErrorDebounce = (newErrorState: boolean): void => {
		setErrorDebounce((prev) => ({
			...prev,
			errorEmail: newErrorState
		}));
	};

	return (
		<StyledForm autoComplete="off" >
			<FormContent container spacing={1} justifyContent={'center'}>
				<Grid item xs={12}>
					<StyledInput
						required
						fullWidth
						name="firstName"
						label={t('views.subscribe.subscribe-component.second-section.inputs.first-name.name')}
						value={formData.owner.firstName}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<StyledInput
						required
						fullWidth
						name="lastName"
						label={t('views.subscribe.subscribe-component.second-section.inputs.name.name')}
						value={formData.owner.lastName}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<DebounceInput
						required
						name="email"
						label={t('views.subscribe.subscribe-component.second-section.inputs.email.name')}
						value={formData.owner.email}
						onChange={(e): void => setFormData({
							...formData,
							owner: {
								...formData.owner,
								email: e.target.value
							}
						})}
						checkVal='email'
						methodRequest='OWNER'
						onErrorDebounce={updateErrorDebouncePhone}
					/>
				</Grid>
				<Grid item xs={12}>
					<DebounceInput
						required
						name="phone"
						label={t('views.subscribe.subscribe-component.second-section.inputs.phone.name')}
						value={formData.owner.phone}
						onChange={(e): void => setFormData({
							...formData,
							owner: {
								...formData.owner,
								phone: e.target.value
							}
						})}
						checkVal='phone'
						methodRequest='OWNER'
						onErrorDebounce={updateErrorDebounce}
					/>
				</Grid>
			</FormContent>
		</StyledForm>
	);
};

export default ContactForm;
