import { FC, SetStateAction, useState } from 'react';
import Layout from 'components/layout';
import { Box } from '@mui/material';
import Footer from 'components/footer';
import { Outlet } from 'react-router-dom';
import Header from '../header';

export const LandingRoutes: FC = () => {
	const setShowSubscribe = useState<boolean>(false)[1];

	const handleSetShowSubscribe = (_value: SetStateAction<boolean>): void => {
		const element = document.querySelector('#form-action');
		if (element) {
			setShowSubscribe(true);
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}
	};

	const scrollToSection = (id: string): void => {
		const section = document.getElementById(id);
		if (section) {
			section.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	return (
		<Box overflow={'hidden auto'}>
			<Header setShowSubscribe={handleSetShowSubscribe} />
			<Layout>
				<Outlet />
			</Layout>
			<Footer scrollToSection={scrollToSection} />
		</Box>
	);
};
