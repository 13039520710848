import React from 'react';
import { Grid, MenuItem, } from '@mui/material';
import { StyledInput } from 'components/input/base/index.styled';
import { ClientFormData } from 'hooks/fetch-hooks/use-service-request/index.types';
import useCounties from 'hooks/fetch-hooks/use-counties';
import useCities from 'hooks/fetch-hooks/use-cities';
import { useTranslation } from 'react-i18next';
import { FormContent, StyledForm } from '../index.styled';

interface IProps {
	formData: ClientFormData
	setFormData: (data: ClientFormData) => void;
}

const WorkplaceForm: React.FC<IProps> = ({ formData, setFormData }) => {
	const { counties, error: errorCounties, loading: loadingCounties } = useCounties();
	const { cities, error: errorCities, loading: loadingCities } = useCities(counties[formData.workplace.county]);
	const { t } = useTranslation();

	return (
		<StyledForm autoComplete="off" >
			<FormContent container spacing={1} justifyContent={'center'}>
				<Grid item xs={12}>
					<StyledInput
						required
						fullWidth
						name="name"
						label={t('views.subscribe.subscribe-component.first-section.inputs.work-place-name.name')}
						// eslint-disable-next-line
						placeholder={t('views.subscribe.subscribe-component.first-section.inputs.work-place-name.placeholder')}
						value={formData.workplace.name}
						onChange={(e): void => setFormData({
							...formData,
							workplace: {
								...formData.workplace,
								name: e.target.value
							}
						})}
					/>
				</Grid>
				<Grid item xs={12}>
					<StyledInput
						required
						fullWidth
						select
						name="county"
						label={t('views.subscribe.subscribe-component.first-section.inputs.county.name')}
						value={formData.workplace.county}
						onChange={(e): void => setFormData({
							...formData,
							workplace: {
								...formData.workplace,
								county: e.target.value
							}
						})}
						disabled={loadingCounties || !!errorCounties}
					>
						{Object.entries(counties).map(county => (
							<MenuItem key={county[1]} value={county[0]}>{county[0]}</MenuItem>
						))}
					</StyledInput>
				</Grid>
				<Grid item xs={12}>
					<StyledInput
						required
						fullWidth
						select
						name="city"
						label={t('views.subscribe.subscribe-component.first-section.inputs.city.name')}
						value={formData.workplace.city}
						onChange={(e): void => setFormData({
							...formData,
							workplace: {
								...formData.workplace,
								city: e.target.value
							}
						})}
						disabled={loadingCities || !!errorCities || !formData.workplace.county}
					>
						{cities.map(city => (
							<MenuItem key={city} value={city}>{city}</MenuItem>
						))}
					</StyledInput>
				</Grid>
			</FormContent>
		</StyledForm>
	);
};

export default WorkplaceForm;
