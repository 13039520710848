import { FC, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loadable from 'components/loadable';
import { LandingRoutes } from 'components/routes';

const Landing = Loadable(lazy(() => import('views/landing')));
const RequestSuccessPage = Loadable(lazy(() => import('views/request-success')));
const ContactPage = Loadable(lazy(() => import('views/contact')));
const AllBlogsPage = Loadable(lazy(() => import('views/blog/all-blogs')));
const SingleBlogPage = Loadable(lazy(() => import('views/blog/single-blog')));
const SubscribePage = Loadable(lazy(() => import('views/subscribe/component')));

const Views: FC = () => (
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<LandingRoutes />}>
				<Route path="/" element={<Landing />} />
				<Route path="/request-success" element={<RequestSuccessPage />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/blogs" element={<AllBlogsPage />} />
				<Route path="/blogs/:id" element={<SingleBlogPage />} />
				<Route path="/subscribe" element={<SubscribePage />} />
			</Route>
		</Routes>
	</BrowserRouter>
);
export default Views;
