import { FC, useState } from 'react';
import { AccordionDetails, AccordionSummary, Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { IModuleData } from 'hooks/fetch-hooks/use-modules/index.types';
import { FeatureTitleText, StyledAccordion, StyledGridContainer } from './index.styled';

interface IProps {
	feature: IModuleData;
	added?: boolean;
	onCheckedChange: (newModule: string) => void;
}

const FeatureAccordion: FC<IProps> = ({ feature, added, onCheckedChange }) => {
	const [open, setOpen] = useState(false);

	return (
		<StyledAccordion expanded={open}>
			<AccordionSummary
				sx={{ pointerEvents: 'none' }}
				expandIcon={
					<Tooltip title='Informatii'>
						<ExpandMore
							onClick={() => setOpen((prev) => !prev)}
							sx={{
								pointerEvents: 'auto',
							}}
						/>
					</Tooltip>
				}>
				<StyledGridContainer container>
					<Grid container item xs={8}>
						<FeatureTitleText variant='h6'>
							{feature.name}
						</FeatureTitleText>
					</Grid>
					<Grid container item xs={3}>
						<Typography variant='h5' color='primary.dark'>
							{feature.price} lei
						</Typography>
					</Grid>
					<Grid container item xs={1}>
						<Checkbox
							sx={{ pointerEvents: 'auto' }}
							name={feature._id}
							value={feature?._id}
							checked={added}
							onChange={(e) => onCheckedChange(e.target.value)}
						/>
					</Grid>
				</StyledGridContainer>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>Lorem ipsum desc</Typography>
			</AccordionDetails>
		</StyledAccordion>
	);
};

FeatureAccordion.defaultProps = {
	added: false,
};

export default FeatureAccordion;
