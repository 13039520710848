import { TextField, TextFieldProps, styled } from '@mui/material';
import { FC } from 'react';

export const StyledInput = styled(TextField as FC<TextFieldProps>)(({ theme }) => ({
	width: '100%',
	minWidth: '300px',
	maxWidth: '600px',

	'.MuiFormLabel-root': {
		color: theme.palette.common.black
	}
}));