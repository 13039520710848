import { useEffect, useState } from 'react';
import { UseCitiesReturnType } from './index.types';
import { requestCities } from './index.actions';

function useCities(countyIso?: string): UseCitiesReturnType {
	const [cities, setCities] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<boolean | string>(false);

	useEffect(() => {
		countyIso && (async (): Promise<void> => {
			setLoading(true);
			try {
				const countiesRetrieved = await requestCities(countyIso);
				if (countiesRetrieved instanceof Array) setCities(countiesRetrieved);
				else if (typeof countiesRetrieved === 'string') setError(countiesRetrieved);
				else setError(true);
			} catch (e) {
				if (e instanceof Error) setError(e.message);
				else setError(true);
			} finally {
				setLoading(false);
			}
		})();
	}, [countyIso]);

	return { cities, error, loading };
}

export default useCities;
