import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import Logo from 'components/logo';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HEADER_TEXT from './assets/header.text.json';
import {
	FooterWrapper,
	FooterLink,
} from './styled';
import MOCK_SERVICES from './assets';
import { descItems } from './assets/description';

interface IProps {
	scrollToSection: (id: string) => void;
}
export interface DescriptionItem {
	id: string;
	title: string;
	path: string;
}

export const Footer: FC<IProps> = ({ scrollToSection }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleFooterLinkClick = (id: string, path: string): void => {
		if (path) {
			navigate(path);
		} else {
			scrollToSection(id);
		}
	};

	return (
		<FooterWrapper>
			<Box>
				<Box>
					<Logo />
				</Box>
			</Box>

			<Box>
				<Typography variant="h5" gutterBottom>{t('views.components.footer.first-section.name')}</Typography>
				{descItems.map((desc: DescriptionItem) => (
					<FooterLink key={desc.id} onClick={() => handleFooterLinkClick(desc.id, desc.path)}>
						<Typography>{t(`views.components.footer.first-section.links.${desc.title}`)}</Typography>
					</FooterLink>
				))}
			</Box>

			<Box>
				<Typography variant="h5" gutterBottom>{t('views.components.footer.second-section.name')}</Typography>
				{MOCK_SERVICES.map((service) => (
					<FooterLink key={service.id} onClick={() => scrollToSection(service.id)}>
						<Typography>{t(`views.components.footer.second-section.links.${service.name}`)}</Typography>
					</FooterLink>
				))}
			</Box>

			<Box>
				<Typography variant="h5" gutterBottom>{t('views.components.footer.third-section.name')}</Typography>
				{HEADER_TEXT.map((txt) => (
					<FooterLink key={txt.name} onClick={() => scrollToSection(txt.id)}>
						<Typography>{t(`views.components.footer.third-section.links.${txt.name}`)}</Typography>
					</FooterLink>
				))}
			</Box>
		</FooterWrapper >
	);
};