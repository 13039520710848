/* eslint-disable indent */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Typography } from '@mui/material';

export const Box = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #043e7d;
  position: absolute;
  top: 0px;
  border-radius: 10px;
  padding: 5px;
  margin: 0
`;

export const Container = styled(motion.div)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 700px;
  height: 150px;
  background-color: #fafafafa;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  // clip-path: polygon(12% 0, 88% 0, 100% 50%, 88% 100%, 12% 100%, 0 50%);
  margin: 10px;
`;

export const Text = styled(motion.div)`
  font-size: 24px;
  font-weight: bold;
  color: black;
  text-align: center;
  margin-top: 15px;
  align-items: center;
`;

export const Video = styled.video`
  width: 20%;
  right: 0;
  bottom: 0;
  object-fit: cover;
  max-height: 300px;
`;

export const StyleGrid = styled.div<{ direction: 'left' | 'right' }>`
  display: flex;
  height: 300px;
  align-items: center;
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  flex-direction: ${({ direction }) =>
    direction === 'left' ? 'row' : 'row-reverse'};
  justify-content: center;
  gap: 20px;
`;

export const Title = styled(motion.div)`
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 4px 8px;
  align-items: center;
`;

export const Center = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TxtHeader = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;
