import { FC } from 'react';
import Flex from 'components/flex';
import { IModuleData } from 'hooks/fetch-hooks/use-modules/index.types';
import { ClientFormData } from 'hooks/fetch-hooks/use-service-request/index.types';
import FeatureAccordion from './component';

interface IProps {
    modules: IModuleData[];
    formData: ClientFormData;
    handleCheckModule: (newModule: string) => void;
}

const Modules: FC<IProps> = ({ modules, formData, handleCheckModule }) => (
	<Flex maxWidth={'900px'} width={'100%'} mt={3}>
		{ modules.map((module) => (
			<FeatureAccordion key={`module-${module?._id}`} feature={module}
				added={formData.modules.includes(module._id)} onCheckedChange={handleCheckModule} />
		))}
	</Flex>
);

export default Modules;