import { FC, ReactNode } from 'react';
import { styled, BoxProps, Box } from '@mui/material';
import { HEADER_HEIGHT } from 'utils/constants';

const Wrapper: FC<BoxProps> = styled(Box as FC<BoxProps>)(() => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginTop: `${HEADER_HEIGHT}px`,
	height: '100%',
	overflow: 'hidden'
}));

interface LayoutProps {
	children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => (
	<Wrapper>{children}</Wrapper>
);

export default Layout;