import { Alert, IconButton, Snackbar } from '@mui/material';
import { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface NotificationsRendererProps {
	message: string | null;
    error: boolean;
	destroy: () => void;
}

const action = (callback: () => void): ReactNode => (
	<IconButton size='small' aria-label='close' color='inherit' onClick={callback}>
		<CloseIcon fontSize='small' />
	</IconButton>
);

const NotificationsRenderer: FC<NotificationsRendererProps> = ({ message, error, destroy }) => {
	if (typeof message !== 'string') return null;

	return (
		<Snackbar
			sx={{ top: '80px!important' }}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={!!message}
			autoHideDuration={3000}
			onClose={(): void => destroy()}>
			<Alert severity={ error ? 'error' : 'success'} action={action(() => destroy())}>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default NotificationsRenderer;
