import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../languages/en.json';
import translationRO from '../languages/ro.json';

export const resources = {
	en: {
		translation: translationEN,
	},
	ro: {
		translation: translationRO,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: 'ro',
	fallbackLng: 'ro', // use en if detected lng is not available

	keySeparator: '.', // we do not use keys in form messages.welcome

	interpolation: {
		escapeValue: false, // react already safes from xss
	},
});

export default i18n;
