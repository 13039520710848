import { AxiosError } from 'axios';
import { requestGuests } from 'utils/config/axios';
import { decryptData } from 'utils/functions/decript';
import { IModuleData } from './index.types';

async function fetchModules(): Promise<IModuleData[]> {
	try {
		const res = await requestGuests.get('modules');
		const data = decryptData(res) as { modules: IModuleData[] };
		return data?.modules || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get modules.');
	}
}

async function fetchExtraModules(subId: string): Promise<IModuleData[]> {
	try {
		const res = await requestGuests.get(`modules-extra?subscription=${subId}`);
		const data = decryptData(res) as { modules: IModuleData[] };
		return data?.modules || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get extra modules.');
	}
}

export { fetchModules, fetchExtraModules };