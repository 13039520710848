import { AxiosError } from 'axios';
import config from 'env.config';
import { requestGuests } from 'utils/config/axios';
import { IValidateAppName, IValidateCui, IValidateOwner } from './index.types';

async function validateCui(params: IValidateCui): Promise<number> {
	try {
		const res = await requestGuests.get(
			`company/validate?application=${config.applicationId}&&cui=${ params.cui }`
		);
		return res.status || 500;
	} catch (e) {
		const error = e as AxiosError;
		const data = error?.response;
		return data?.status || 500;
	}
}

async function validateAppName(params: IValidateAppName): Promise<string | number> {
	try {
		const res = await requestGuests.get(
			`sites/validate?application=${config.applicationId}&&name=${params.name}`
		);
		return res.status || 500;
	} catch (e) {
		const error = e as AxiosError;
		const data = error?.response;
		return data?.status || 500;
	}
}

async function validateOwner(params: IValidateOwner): Promise<string | number> {
	try {
		const res = await requestGuests.get(
			`owners/validate?application=${config.applicationId}&&${params.forValidate}=${params.value}`
		);
		return res.status || 500;
	} catch (e) {
		const error = e as AxiosError;
		const data = error?.response;
		return data?.status || 500;
	}
}

export { validateCui, validateAppName, validateOwner };