import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

export const FooterWrapper = styled(Box as FC<BoxProps>)(() => ({
	backgroundColor: '#f8f9fa',
	display: 'flex',
	justifyContent: 'space-around',
	alignItems: 'flex-start',
	padding: '40px 20px',
	borderTop: '1px solid #e0e0e0',
	flexWrap: 'wrap',
	width: '100%',
	color: '#4a4a4a',
	fontSize: '0.9rem',
}));

export const FirstColumnWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	textAlign: 'left',
	width: '250px',
	marginBottom: '20px',
}));

export const ThirdColumnWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	textAlign: 'left',
	width: '250px',
	marginBottom: '20px',
}));

export const FooterLink = styled(Box as FC<BoxProps>)(() => ({
	cursor: 'pointer',
	color: '#4a4a4a',
	margin: '8px 0',
	transition: 'transform 0.3s ease',

	'&:hover': {
		transform: 'scale(1.05)',
	},

	'&:hover .MuiTypography-root': {
		color: '#0047AB',
	},
}));