import { Accordion, Grid, Typography, styled } from '@mui/material';

export const StyledAccordion = styled(Accordion)({
	width: '100%',
	boxShadow: 'none',
});

export const StyledGridContainer = styled(Grid)({
	display: 'flex',
	alignItems: 'center' 
});

export const FeatureTitleText = styled(Typography)({
	width: '100%',
	textAlign: 'left'
});
