import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import Flex from 'components/flex';
import NotificationsRenderer from 'components/notification';
import config from 'env.config';
import useModules from 'hooks/fetch-hooks/use-modules';
import { IModuleData } from 'hooks/fetch-hooks/use-modules/index.types';
import usePlans from 'hooks/fetch-hooks/use-plans';
import { IPlansData } from 'hooks/fetch-hooks/use-plans/index.types';
import useServiceRequest from 'hooks/fetch-hooks/use-service-request';
import { ClientFormData } from 'hooks/fetch-hooks/use-service-request/index.types';
import { FC, useState } from 'react';
import { hasEmptyOrNull } from 'utils/functions/check-empty-null';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppForm from '../app-form';
import ContactForm from '../contact-form';
import PlanFeatures from '../plan-features';
import WorkplaceForm from '../workplace-form';

export interface IErrorDebounce {
	errorName: boolean;
	errorEmail: boolean;
	errorPhone: boolean;
}

export const initialStateClientForm: ClientFormData = {
	site: { name: '' },
	owner: { firstName: '', lastName: '', email: '', phone: '' },
	company: { cui: '' },
	applicationId: config.applicationId,
	workplace: { name: '', city: '', county: '' },
	subscriptionPlan: '',
	modules: [],
};

const Subscribe: FC = () => {
	const navigate = useNavigate();
	const debounceError: IErrorDebounce = { errorName: true, errorEmail: true, errorPhone: true };
	const [formData, setFormData] = useState<ClientFormData>(initialStateClientForm);
	const { sentRequest, loading, error, message, resetFlags } = useServiceRequest();
	const [errorDebounce, setErrorDebounce] = useState<IErrorDebounce>(debounceError);
	const { data: plansData = [], loading: loadingPlans } = usePlans<IPlansData[]>(true);
	// eslint-disable-next-line
	const { data: modulesData = [], loading: loadingModules } = useModules<IModuleData[]>(false, formData.subscriptionPlan);
	const { t } = useTranslation();
	
	const handleSubmit = (data: ClientFormData): void => {
		sentRequest(data, () => navigate('/request-success'));
		setFormData(initialStateClientForm);
	};

	const hasEmpty = hasEmptyOrNull(formData);
	const hasDebounceError = errorDebounce?.errorEmail || errorDebounce?.errorName || errorDebounce?.errorPhone;
	const isDisabled = hasEmpty || hasDebounceError;

	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				padding: 4,
			}}
		>
			<Box
				width="100%"
				maxWidth="600px"
				display="flex"
				flexDirection="column"
				height={'100%'}
				sx={{ gap: 3 }}
			>
				<Box display={'flex'} justifyContent={'center'} width={'100%'} marginTop={'20px'}>
					<Typography variant="h2">{t('views.subscribe.subscribe-component.title')}</Typography>
				</Box>

				<Box display="flex" alignItems="center" gap={2} my={2}>
					<Divider sx={{ flex: 1 }} />
					<Typography variant="h5" align="center">
						{t('views.subscribe.subscribe-component.first-section.title')}
					</Typography>
					<Divider sx={{ flex: 1 }} />
				</Box>
				<Box marginBottom={2}>
					<AppForm formData={formData} setFormData={setFormData} setErrorDebounce={setErrorDebounce} />
					<WorkplaceForm formData={formData} setFormData={setFormData} />
				</Box>

				<Box display="flex" alignItems="center" gap={2} my={2}>
					<Divider sx={{ flex: 1 }} />
					<Typography variant="h5" align="center">
						{t('views.subscribe.subscribe-component.second-section.title')}
					</Typography>
					<Divider sx={{ flex: 1 }} />
				</Box>
				<Box marginBottom={3}>
					<ContactForm formData={formData} setFormData={setFormData} setErrorDebounce={setErrorDebounce} />
				</Box>

				{loadingPlans ? (
					<Flex justifyCenter>{t('views.subscribe.subscribe-component.third-section.loading')}</Flex>
				) : (
					<Box>
						<Box display="flex" alignItems="center" gap={2} my={2} mb={10}>
							<Divider sx={{ flex: 1 }} />
							<Typography variant="h5" align="center">
								{t('views.subscribe.subscribe-component.third-section.title')}
							</Typography>
							<Divider sx={{ flex: 1 }} />
						</Box>
						<PlanFeatures
							formData={formData}
							setFormData={setFormData}
							plansData={plansData}
							loadingModules={loadingModules}
							moduleData={modulesData}
						/>
					</Box>
				)}

				<Button
					onClick={() => handleSubmit(formData)}
					disabled={isDisabled}
					variant="contained"
					color="primary"
					type="submit"
					sx={{
						marginTop: 2,
						width: '100%',
						height: '40px',
					}}
				>
					{loading ? <CircularProgress size="16px" /> :
					 t('views.subscribe.subscribe-component.third-section.button')}
				</Button>
				<NotificationsRenderer message={message} error={error} destroy={resetFlags} />
			</Box>
		</Box>
	);
};

export default Subscribe;
