import { FC, useState } from 'react';
import { Box, Dialog, Typography, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Flex from 'components/flex';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubscribeButton } from 'views/landing/components/hero/styled';
import { TxtHeader } from 'views/landing/components/animed-text/index.styled';
import Subscribe from 'views/landing/components/subsribe-page/component';
import HeaderWrapper, { RightSideWrapper, SubscribeText, 
	SubscribeWrapper, TextWrapper, TranslateButtonWrapper } from './index.styled';
import Logo from '../logo';

interface IProps {
  setShowSubscribe: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: FC<IProps> = ({ setShowSubscribe: _setShowSubscribe }) => {
	const [showSubscribe, setShowSubscribeLocal] = useState(false);
	const [language, setLanguage] = useState('ro'); 
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();

	const scrollToSection = (id: string): void => {
		const section = document.getElementById(id);
		if (section) {
			section.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		} else {
			console.log(`Section with ID: ${id} not found`);
		}
	};

	const handleSubscribeClick = (): void => {
		navigate('/subscribe');
	};

	const handleLanguageChange = (event: SelectChangeEvent<string>): void => {
		const selectedLanguage = event.target.value as string;
		setLanguage(selectedLanguage);
		i18n.changeLanguage(selectedLanguage);
	};

	return (
		<HeaderWrapper>
			<Box onClick={(): void => navigate('/')} marginTop={'20px'}>
				<Logo />
			</Box>
			<TextWrapper>
				<TxtHeader onClick={() => scrollToSection('module')}>
					{t('views.components.header.links.modules')}
				</TxtHeader>
				<TxtHeader onClick={() => scrollToSection('plans')}>
					{t('views.components.header.links.plans')}
				</TxtHeader>
				<TxtHeader onClick={() => scrollToSection('despre')}>
					{t('views.components.header.links.about-us')}
				</TxtHeader>
			</TextWrapper>
			<RightSideWrapper>
				<TranslateButtonWrapper>
					<Select
						value={language}
						onChange={handleLanguageChange}
						displayEmpty
						inputProps={{ 'aria-label': 'Select Language' }}
						sx={{ minWidth: 120 }}
					>
						<MenuItem value="ro">{t('views.components.header.buttons.translate.ro')}</MenuItem>
						<MenuItem value="en">{t('views.components.header.buttons.translate.en')}</MenuItem>
					</Select>
				</TranslateButtonWrapper>
				<Flex gap={2}>
					<SubscribeButton onClick={handleSubscribeClick}>
						{t('views.components.header.buttons.subscribe')}
					</SubscribeButton>
				</Flex>
			</RightSideWrapper>

			<Dialog
				open={showSubscribe}
				onClose={() => setShowSubscribeLocal(false)}
				maxWidth="md"
				fullWidth
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}
			>
				<SubscribeText>
					<Typography variant='h4'>{t('Trimite o cerere de abonare')}</Typography>
				</SubscribeText>
				<SubscribeWrapper>
					<Subscribe />
				</SubscribeWrapper>
			</Dialog>
		</HeaderWrapper>
	);
};

export default Header;
