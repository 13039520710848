import { Box, BoxProps, Button, ButtonProps, Typography, TypographyProps, styled } from '@mui/material';
import { FC } from 'react';
import { HEADER_HEIGHT } from 'utils/constants';
// import clinic from './assets/clinic.jpg';
import Dental from './assets/dental.png';
 
export const HeroWrapper = styled(Box as FC<BoxProps>)(() => ({
	width: '100%',
	display: 'flex',
	position: 'relative',
	minHeight: '130vh',
	height: '120vh',
	overflow: 'hidden', 
	'&::before': {
		content: '""',
		position: 'absolute',
		width: '100%',
		height: '90%',
		backgroundImage: `url(${Dental})`,
		backgroundSize: 'cover', 
		backgroundPosition: 'center', 
		backgroundRepeat: 'no-repeat',
		filter: 'brightness(50%)',
	}
}));

export const SectionBoxHero = styled(Box as FC<BoxProps>)(() => ({
	width: '50%',
	display: 'flex',
	paddingTop: '120px',
	position: 'relative',
	minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
	height: `calc(100vh - ${HEADER_HEIGHT}px)`,
	
}));

// export const SectionImageHero = styled(Box as FC<BoxProps>)(() => ({
// 	width: '50%',
// 	display: 'flex',
// 	position: 'relative',
// 	minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
// 	height: '119vh',
// 	backgroundImage: `url(${Dental})`,
// 	backgroundSize: 'cover',
// 	backgroundPosition: 'center', 
// 	backgroundRepeat: 'no-repeat',
// 	overflow: 'hidden', 
// 	'&::before': {
// 		content: '""',
// 		position: 'absolute',
// 		width: '100%',
// 		height: '100%',
// 		backgroundPosition: 'center',
// 		backgroundRepeat: 'no-repeat',
// 		backgroundSize: 'cover',
// 		filter: 'brightness(50%)',
// 	}
// }));

export const GeometryBox = styled(Box as FC<BoxProps>)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	position: 'absolute',
	alignItems: 'flex-end',
	bottom: 0,
	left: 0,
	height: '250px',
	backgroundColor: theme.palette.background.default,
	clipPath: 'polygon(50% 0%, 100% 30%, 100% 100%, 0 100%, 0 30%)'
}));

export const HeroTitle = styled(Typography as FC<TypographyProps>)(({ theme }) => ({
	color: theme.palette.common.white,
	fontSize: '5rem',
	maxWidth: '100%',
	position: 'absolute',
	fontWeight: 600,
	textShadow: `1px 3px 2px ${theme.palette.primary.main}`,
	marginLeft: '50px'
}));

export const WrapSubscribeButton = styled(Box as FC<BoxProps>)(() => ({
	width: '500px',
	display: 'flex',
	marginTop: '44px'
}));

export const SubscribeButton = styled(Button as FC<ButtonProps>)(({ theme }) => ({
	width: '300px',
	height: '50px',
	fontSize: '20px',
	fontWeight: 600,
	textShadow: `1px 3px 2px ${theme.palette.primary.main}`
}));

export const MotoText = styled(Typography as FC<TypographyProps>)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: '100%',
	marginBottom: '60px',
	fontSize: '2rem',
	textAlign: 'center',
}));

export const MotoDisplayWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	padding: 2
}));

export const MotoWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	width: '60%'
}));

export const FirstColumnWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	flexDirection: 'column',
	width: '40%'
}));

export const MotoImgWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	alignItems: 'center',
	marginBottom: 2
}));

export const SecondColumnWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	flexDirection: 'column',
	width: '40%'
}));

