import { DescriptionItem } from '../component';

export const descItems: DescriptionItem[] = [
	{
		id: '1',
		title: 'about-us',
		path: '/about'
	},
	{
		id: '2',
		title: 'contact',
		path: '/contact'
	},
	{
		id: '3',
		title: 'privacy-and-policy',
		path: '/privacy'
	},
	{
		id: '4',
		title: 'terms-and-conditions',
		path: '/terms'
	},
	{
		id: '5',
		title: 'blog',
		path: '/blogs'
	}
];