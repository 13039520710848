import config from 'env.config';
import { AxiosError } from 'axios';
import { decryptData } from 'utils/functions/decript';
import { requestGuests } from 'utils/config/axios';
import { IPlansData } from './index.types';

async function fetchPlans(): Promise<IPlansData[]> {
	try {
		const res = await requestGuests.get(`subscription-plans?application=${config.applicationId}`);
		const data = decryptData(res) as { subscriptionPlans: IPlansData[] };
		return data?.subscriptionPlans || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to get subscription plans.');
	}
}

export { fetchPlans }; 