import { AxiosError } from 'axios';
import { requestGuests } from 'utils/config/axios';
import { encryptData } from 'utils/functions/decript';
import { ClientFormData } from './index.types';

async function postServiceRequest(params: ClientFormData): Promise<string | {message: string, error: boolean}> {
	try {
		const encData = encryptData(params);
		const res = await requestGuests.post('requests', encData);
		return res.data?.message || 'Successfully sent request!';
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		return {message : data?.error || 'Failed to send request.',
			error: true
		};
	}
}

export { postServiceRequest };