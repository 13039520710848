import { Card, Grid, styled } from '@mui/material';

export const StyledCard = styled(Card)({
	boxShadow: 'none',
	width: '100%',
	minHeight: 40,
	padding: 20
});

export const StyledGridContainer = styled(Grid)({
	display: 'flex',
	alignItems: 'center' 
});
