import { FC, useEffect } from 'react';
import { InputAdornment } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import useDebounce from 'hooks/use-debounce';
import useValidateData from 'hooks/fetch-hooks/use-validate-data';
import {
	IValidateAppName,
	IValidateCui,
	IValidateMethods,
	IValidateOwner,
} from 'hooks/fetch-hooks/use-validate-data/index.types';
import { StyledInput } from '../base/index.styled';

export interface DebounceInputProps {
	name: string;
	label: string;
	placeholder?: string;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	required?: boolean;
	onCompleteDebounce?: () => void;

	methodRequest?: keyof IValidateMethods;
	checkVal?: ICheckVal;
	onErrorDebounce?: (newErrorState: boolean) => void;
}

type ICheckVal = 'appName' | 'email' | 'phone' | 'cui' | '';

const DebounceInput: FC<DebounceInputProps> = ({
	label,
	name,
	placeholder,
	onChange,
	required = false,
	value,
	onCompleteDebounce,
	methodRequest = 'APP_NAME',
	checkVal = '',
	onErrorDebounce,
}) => {
	const debouncedValue = useDebounce(value, 1000);

	const { validateData, isValid, error, errorMessage, clearError } = useValidateData();

	const executeValidation = (): void => {
		switch (checkVal) {
			case 'cui':
				validateData<IValidateCui>({ methodRequest, paramsObject: { cui: value } });
				break;
			case 'appName':
				validateData<IValidateAppName>({ methodRequest, paramsObject: { name: value } });
				break;
			case 'email':
				validateData<IValidateOwner>({ methodRequest, paramsObject: { forValidate: 'email', value } });
				break;
			case 'phone':
				validateData<IValidateOwner>({ methodRequest, paramsObject: { forValidate: 'phone', value } });
				break;
			default:
				throw new Error('Invalid validation method');
		}
	};

	const handleRequest = (): void => {
		executeValidation();
		onCompleteDebounce && onCompleteDebounce();
	};

	useEffect(() => {
		debouncedValue?.length > 3 && debouncedValue === value && handleRequest();
		value?.length === 0 && clearError();
		// eslint-disable-next-line
	}, [debouncedValue, value]);

	useEffect(() => {
		typeof error === 'boolean' && onErrorDebounce && onErrorDebounce(error!);
		// eslint-disable-next-line
	}, [error]);
	return (
		<StyledInput
			fullWidth
			name={name}
			label={label}
			value={value}
			placeholder={placeholder}
			required={required}
			onChange={onChange}
			InputProps={{
				endAdornment: typeof isValid === 'boolean' && (
					<InputAdornment position='end'>
						{isValid ? (
							<CheckCircleOutline sx={{ color: 'green' }} />
						) : (
							<ErrorOutline sx={{ color: 'red' }} />
						)}
					</InputAdornment>
				),
			}}
			error={error || false}
			helperText={error && errorMessage && errorMessage}
		/>
	);
};

DebounceInput.defaultProps = {
	required: false,
	onCompleteDebounce: undefined,
	checkVal: '',
	placeholder: '',
	methodRequest: 'APP_NAME',
	onErrorDebounce: undefined,
};

export default DebounceInput;
