type NestedObject = Record<string, any>;

export function hasEmptyOrNull(obj: NestedObject): boolean {
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key];
			if (value === null || value === '') {
				return true;
			} if (typeof value === 'object' && value !== null) {
				// Recursive call for nested objects
				if (hasEmptyOrNull(value)) {
					return true;
				}
			}
		}
	}
	return false;
}