import { AppBar as MuiAppBar, AppBarProps, styled, Toolbar, Box, BoxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { HEADER_HEIGHT } from 'utils/constants';

const headerHeight = HEADER_HEIGHT;

const AppBar: FC<AppBarProps> = styled(MuiAppBar as FC<AppBarProps>, {
	shouldForwardProp: (prop: string) => prop !== 'isDrawerOpen',
})(({ theme }) => ({
	display: 'flex',
	backgroundColor: '#fafafafa',
	boxShadow: 'none',
	padding: '0',
	border: `1px solid ${theme.palette.divider}`,
	zIndex: theme.zIndex.drawer + 1,
	width: '100%',
	height: `${headerHeight}px`,
}));

export const TextWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '30%',
}));

export const SubscribeWrapper = styled(Box as FC<BoxProps>)(() => ({
	width: '700px',
	display: 'flex',
	justifyContent: 'center',
	margin: '20px',
}));

export const SubscribeText = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	marginTop: '20px'
}));

export const RightSideWrapper = styled(Box as FC<BoxProps>)(() => ({
	display: 'flex',
}));

export const TranslateButtonWrapper = styled(Box as FC<BoxProps>)( () => ({
	display: 'flex',
	alignItems: 'center',
	marginRight: '20px'
}));

interface HeaderWrapperProps {
	children?: ReactNode;
}

const HeaderWrapper: FC<HeaderWrapperProps> = ({ children }) => (
	<AppBar>
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingX: '30px' }}>
			{children}
		</Toolbar>
	</AppBar>
);

HeaderWrapper.defaultProps = {
	children: '',
};

export default HeaderWrapper;
